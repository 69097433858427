<template>
  <el-card shadow="never" v-loading="loading">
    <div slot="header">
      <span class="iconfont icon-Role">&nbsp;角色管理</span>
    </div>
    <div class="card_header_flex">
      <el-button type="primary" size="small" @click="dialogAddRole=true">新增角色</el-button>
    </div>
    <el-table :data="list.slice((currentPage-1)*pageSize,currentPage*pageSize)" size="mini">
      <el-table-column prop="roleName" label="角色名称">
        <template slot-scope="scope">
          <span>{{scope.row.roleName}}</span>&nbsp;
          <el-tag type="success" v-if="scope.row.type=='preinstall'" size="mini">默认</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="description" label="描述"></el-table-column>
      <el-table-column prop="userCount" label="员工数量"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            v-if="scope.row.type=='preinstall'"
            @click="openShow(scope.row)"
          >查看</el-button>
          <el-button
            type="text"
            size="small"
            v-show="scope.row.type=='normal'"
            @click="openEdit(scope.row)"
          >编辑</el-button>
          <el-button
            type="text"
            size="small"
            v-show="scope.row.type=='normal'"
            @click="onSubmitDelRole(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page_right">
      <el-pagination
        background
        layout="total,prev, pager, next ,jumper"
        :total="list.length"
        :page-size="pageSize"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>

    <!-- 添加角色 -->
    <el-dialog
      :visible.sync="dialogAddRole"
      :close-on-click-modal="false"
      width="45%"
      @closed="closeDialogAddRole"
    >
      <span slot="title">添加角色</span>
      <el-form
        :model="form"
        ref="form"
        label-width="150px"
        size="small"
        style="padding:20px"
        hide-required-asterisk
      >
        <el-form-item
          label="角色名称"
          prop="roleName"
          :rules="{ required: true, message: '请输入您的角色名称', trigger: 'blur' }"
        >
          <el-input v-model="form.roleName" maxlength="10" show-word-limit placeholder="请输入您的角色名称"></el-input>
        </el-form-item>
        <el-form-item
          label="角色描述"
          prop="description"
          :rules="[{ required: true, message: '请输入您的角色描述', trigger: 'blur' }]"
        >
          <el-input
            v-model="form.description"
            type="textarea"
            maxlength="100"
            show-word-limit
            placeholder="请输入您的角色描述"
          ></el-input>
        </el-form-item>
        <el-form-item label="电视管家小程序权限">
          <el-switch v-model="form.iptvSteward" active-value="on" inactive-value="off"></el-switch>
        </el-form-item>
        <el-form-item label="客房助手小程序权限">
          <el-switch v-model="form.iptvHelper" active-value="on" inactive-value="off"></el-switch>
        </el-form-item>
        <el-form-item label="后台权限">
          <div class="down-tree">
            <el-tree
              :data="menuData"
              show-checkbox
              default-expand-all
              node-key="menuId"
              ref="tree"
              highlight-current
              :props="{ children: 'subMenu', label: 'name'}"
            ></el-tree>
          </div>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogAddRole=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitAddRole">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改角色 -->
    <el-dialog
      :visible.sync="dialogEditRole"
      :close-on-click-modal="false"
      width="45%"
      @closed="closeDialogEditRole"
    >
      <span slot="title">编辑角色</span>
      <el-form
        :model="editForm"
        ref="editForm"
        label-width="150px"
        size="small"
        style="padding:20px"
        :hide-required-asterisk="true"
      >
        <el-form-item
          label="角色名称"
          prop="roleName"
          :rules="{ required: true, message: '请输入您的角色名称', trigger: 'blur' }"
        >
          <el-input v-model="editForm.roleName" maxlength="10" show-word-limit></el-input>
        </el-form-item>
        <el-form-item
          label="角色描述"
          prop="description"
          :rules="[{ required: true, message: '请输入您的角色描述', trigger: 'blur' }]"
        >
          <el-input v-model="editForm.description" type="textarea" maxlength="100" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="电视管家小程序权限">
          <el-switch v-model="editForm.iptvSteward" active-value="on" inactive-value="off"></el-switch>
        </el-form-item>
        <el-form-item label="客房助手小程序权限">
          <el-switch v-model="editForm.iptvHelper" active-value="on" inactive-value="off"></el-switch>
        </el-form-item>
        <el-form-item label="后台权限">
          <div class="down-tree">
            <el-tree
              :data="menuData"
              show-checkbox
              default-expand-all
              :default-checked-keys="editForm.menuList"
              node-key="menuId"
              ref="editTree"
              highlight-current
              :props="{ children: 'subMenu', label: 'name'}"
            ></el-tree>
          </div>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button plain type="primary" size="small" @click="dialogEditRole=false">取 消</el-button>
        <el-button type="primary" size="small" @click="onSubmitEditRole">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 查看角色 -->
    <el-dialog :visible.sync="dialogShowRole" :close-on-click-modal="false" width="45%">
      <span slot="title">查看角色</span>
      <el-form
        :model="editForm"
        ref="editForm"
        label-width="120px"
        size="small"
        style="padding:20px"
        :hide-required-asterisk="true"
      >
        <el-form-item label="角色名称">{{editForm.roleName}}</el-form-item>
        <el-form-item label="角色描述">{{editForm.description}}</el-form-item>

        <el-form-item label="后台权限">
          <div class="down-tree">
            <el-tree
              :data="showMenuData"
              default-expand-all
              node-key="menuId"
              ref="editTree"
              highlight-current
              :props="{ children: 'subMenu', label: 'name'}"
            ></el-tree>
          </div>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button type="primary" size="small" @click="dialogShowRole=false">关 闭</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>
<script>
import {
  getGroupRoleApi,
  getGroupRoleMenuApi,
  addGroupRoleApi,
  editGroupRoleApi,
  delGroupRoleApi
} from "../../api/data";
import Aes from "../../utils/aes";
export default {
  data() {
    return {
      loading: true,
      list: [],
      menuData: [], //菜单权限
      currentPage: 1, //当前页
      pageSize: 10, //一页显示多少条
      dialogAddRole: false, //添加角色窗口
      dialogEditRole: false, //修改角色窗口
      dialogShowRole: false, //查看角色窗口
      form: {
        roleName: "",
        groupId: "",
        description: "",
        menuIds: "",
        iptvSteward: "off",
        iptvHelper: "off"
      },
      editForm: {},
      showMenuData: []
    };
  },
  computed: {
    //当前场所信息
    placesInfo() {
      return JSON.parse(Aes.decode(this.$store.state.user.placesInfo));
    }
  },
  created() {
    this.initLoad();
    this.initMenuRole();
  },
  methods: {
    //初始化角色
    initLoad() {
      getGroupRoleApi({ groupId: this.placesInfo.groupId }).then(res => {
        this.list = res.data;
        this.loading = false;
      });
    },
    //角色初始化菜单权限查询全部菜单
    initMenuRole() {
      getGroupRoleMenuApi().then(res => {
        this.menuData = res.data;
      });
    },
    //添加角色
    onSubmitAddRole() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          this.form.menuIds = this.$refs.tree.getCheckedKeys();
          if (this.form.menuIds == "") {
            this.$message.warning("请勾选后台权限");
            return false;
          }
          this.form.groupId = this.placesInfo.groupId;
          addGroupRoleApi(this.form).then(res => {
            if (res) {
              this.$message.success(res.msg);
              this.initLoad();
              this.dialogAddRole = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    //关闭添加窗口事件
    closeDialogAddRole() {
      this.$refs["form"].resetFields();
    },
    //打开编辑角色窗口
    openEdit(obj) {
      this.dialogEditRole = true;
      this.editForm = { ...obj };
    },
    //提交修改
    onSubmitEditRole() {
      this.$refs["editForm"].validate(valid => {
        if (valid) {
          this.editForm.menuIds = this.$refs.editTree.getCheckedKeys();
          if (this.editForm.menuIds.length == 0) {
            this.$message.warning("请勾选后台权限");
            return false;
          }
          this.editForm.groupId = this.placesInfo.groupId;
          editGroupRoleApi(this.editForm).then(res => {
            if (res) {
              this.$message.success(res.msg);
              this.initLoad();
              this.dialogEditRole = false;
            }
          });
        } else {
          return false;
        }
      });
    },
    //关闭修改事件
    closeDialogEditRole() {
      this.$refs["editForm"].resetFields();
    },
    //查看按钮事件
    openShow(obj) {
      this.dialogShowRole = true;
      this.editForm = { ...obj };
      //需要后台返回已经勾选的权限集合
      getGroupRoleMenuApi({ roleId: obj.roleId }).then(res => {
        this.showMenuData = res.data;
      });
    },
    //删除角色
    onSubmitDelRole(obj) {
      this.$confirm("此操作将永久删除该角色, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delGroupRoleApi({ roleId: obj.roleId }).then(res => {
          if (res) {
            this.$message.success(res.msg);
            this.initLoad();
          }
        });
      });
    },
    //选择第几页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
    }
  }
};
</script>
<style lang='scss'>
.down-tree {
  height: 300px;
  display: block;
  overflow-y: scroll;
  border: 1px solid #d7dae2;
  border-radius: 4px;
}
</style>
